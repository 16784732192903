export enum ApiRequestStatus {
  NotSubmitted,
  InProgress,
  Success,
  Failed
}

export interface IApiRequestResult {
  status: ApiRequestStatus;
  errorCode?: string | null;
}

export enum ApiRequest {
  'SignIn' = 'SignIn',
  'SignUp' = 'SignUp',
  'VerifyYourEmail' = 'VerifyYourEmail',
  'ResendConfirmationCode' = 'ResendConfirmationCode',
  'SubmitApplication' = 'SubmitApplication',
  'ApplicationStatus' = 'ApplicationStatus',
  'VerifyIdentity' = 'VerifyIdentity',
  'ForgotPassword' = 'ForgotPassword',
  'ForgotPasswordSubmit' = 'ForgotPasswordSubmit',
  'GetCashAccount' = 'GetCashAccount',
  'VerifyIdentitySubmit' = 'VerifyIdentitySubmit',
  'VerifyIdentityStatus' = 'VerifyIdentityStatus',
  'GetSingpassOrJumioAuthoriseUrl' = 'GetSingpassOrJumioAuthoriseUrl',
  'GetPersonDataFromMyInfo' = 'GetPersonDataFromMyInfo',
  'GetPersonDataFromJumioStatus' = 'GetPersonDataFromJumioStatus'
}
