import React, { useEffect } from 'react';
import { Switch, Redirect, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { CreateLogin } from '../Pages/CreateLogin';
import { getPagePathWithoutBusinessRegion, Page } from '../models/page';

import { changePage, redirectToExternalUrl, updateAuthenticatedAt } from '../Store/Actions';
import { ROUTE_HISTORY_ACTION_POP, ROUTE_HISTORY_ACTION_REPLACE, pages } from '../models/pagePaths';
import { IStore } from '../Store/Store';
import { useAuthorisationTimeout } from '../hooks/useAuthorisationTimeout';
import { Login } from '../Pages/Login';
import { BaseRoute } from '../Components/BaseRoute';
import { ForgotPassword } from '../Pages/ForgotPassword';
import { createPagePathWithBusinessRegion } from '../models/route';
import { BusinessRegion } from '../configuration/configuration';
import { ProfilePersonalDetails } from '../Pages/Sgp/ProfilePersonalDetails';
import { SingpassConnector } from '../Pages/Sgp/SingpassConnector';
import { SingpassCallback } from '../Pages/Sgp/SingpassCallback';
import { JumioConnector } from '../Pages/Sgp/JumioConnector';
import { ProfileTaxDetails } from '../Pages/Sgp/ProfileTaxDetails';
import { ProfileYourAddress } from '../Pages/Sgp/ProfileYourAddress';
import { FinancialBackground } from '../Pages/Sgp/FinancialBackground';
import { ProfileEmployment } from '../Pages/Sgp/ProfileEmployment';
import { AccountReadyInformation } from '../Pages/Sgp/AccountReadyInformation';
import { KnowledgeExperience } from '../Pages/Sgp/KnowledgeExperience';
import { TermsConfirmation } from '../Pages/Sgp/TermsConfirmation';
import { JumioProcessing } from '../Pages/Sgp/JumioProcessing';
import { Route } from '../Components/Route';
import { SGP_CMC_PLATFORM_SITE } from '../constants/sgp/urlConstants';
import { ApplicationStatus } from '../models/application';
import { usePromoCode } from '../hooks/usePromoCode';
import { Analytics } from '../Components/Analytics';

const nextPageIfNotSubmitApplication = () => Page.SingpassConnector;

const nextPageIfSubmitApplication = (applicationStatus: ApplicationStatus) => {
  if (applicationStatus === ApplicationStatus.ReadyToTrade) {
    redirectToExternalUrl(SGP_CMC_PLATFORM_SITE);
    return undefined;
  }
  return Page.AccountReadyInformation;
};

const nextPageAfterLogin = (applicationStatus: ApplicationStatus, lastApplicationId?: string) =>
  lastApplicationId ? nextPageIfSubmitApplication(applicationStatus) : nextPageIfNotSubmitApplication();

export const SgpRoutes: React.FC = () => {
  // eslint-disable-next-line i18next/no-literal-string
  const { region, language } = { region: 'sgp', language: 'en' };

  usePromoCode();

  const dispatch = useDispatch();
  const history = useHistory();
  const authenticatedAt = useSelector((store: IStore) => store.local.authenticatedAt);
  const page = useSelector((store: IStore) => store.local.page);
  const applicationStatus = useSelector((store: IStore) => store.local.applicationStatus);
  const latestApplicationId = useSelector((store: IStore) => store.local.latestApplicationId);
  const currentPathName = history.location.pathname;
  const shouldLoadLoginPage = history.action === ROUTE_HISTORY_ACTION_POP && currentPathName.includes(getPagePathWithoutBusinessRegion(pages[Page.LogIn].path));
  const shouldLoadCreateLoginPageWithoutCheckStoreState =
    history.action === ROUTE_HISTORY_ACTION_POP && (currentPathName === pages[Page.Home].path || currentPathName === pages[Page.CreateLogIn].path);
  const shouldLoadCreateLoginPageAfterCheckStoreState =
    (history.action === ROUTE_HISTORY_ACTION_POP || history.action === ROUTE_HISTORY_ACTION_REPLACE) && page === Page.Home;
  const shouldLoadCreateLoginPage = shouldLoadCreateLoginPageWithoutCheckStoreState || shouldLoadCreateLoginPageAfterCheckStoreState;
  const shouldLoadSingpassCallbackPage =
    history.action === ROUTE_HISTORY_ACTION_POP && currentPathName.includes(getPagePathWithoutBusinessRegion(pages[Page.SingpassCallback].path));

  // TODO we can use localstorage to resume a more accurate timestamp if we have to restore some other props in the future
  const resumedAuthenticatedAt = dayjs().unix();
  useEffect(() => {
    if (shouldLoadSingpassCallbackPage) {
      dispatch(updateAuthenticatedAt());
    }
  }, [shouldLoadSingpassCallbackPage]);

  const onChangePage = (newPage?: Page) => {
    /* istanbul ignore else */
    if (newPage) {
      dispatch(changePage(newPage));
    }
  };

  useAuthorisationTimeout(dispatch, page, shouldLoadSingpassCallbackPage ? resumedAuthenticatedAt : authenticatedAt);

  useEffect(() => {
    const newPath = createPagePathWithBusinessRegion(page, BusinessRegion.SGP);
    history.push(`${newPath}${window.location.search}`);
  }, [page]);

  if (shouldLoadLoginPage) {
    onChangePage(Page.LogIn);
  } else if (shouldLoadSingpassCallbackPage) {
    onChangePage(Page.SingpassCallback);
  } else if (shouldLoadCreateLoginPage) {
    onChangePage(Page.CreateLogIn);
  }

  return (
    <>
      <Analytics region={region} language={language} />
      <Switch>
        <BaseRoute {...pages[Page.LogIn]}>
          <Login
            onNext={() => onChangePage(nextPageAfterLogin(applicationStatus, latestApplicationId))}
            onForgotPassword={() => onChangePage(Page.ForgotPassword)}
          />
        </BaseRoute>
        <BaseRoute {...pages[Page.ForgotPassword]}>
          <ForgotPassword onNext={() => onChangePage(Page.SingpassConnector)} onPrevious={() => onChangePage(Page.LogIn)} />
        </BaseRoute>
        <Route {...pages[Page.CreateLogIn]}>
          <CreateLogin onNext={() => onChangePage(Page.SingpassConnector)} onLogin={() => onChangePage(Page.LogIn)} onPrevious={() => {}} />
        </Route>
        <Route {...pages[Page.SingpassConnector]}>
          <SingpassConnector onLoadJumio={() => onChangePage(Page.JumioConnector)} />
        </Route>
        <Route {...pages[Page.SingpassCallback]}>
          <SingpassCallback onNext={() => onChangePage(Page.ProfilePersonalDetails)} onLoadJumio={() => onChangePage(Page.JumioConnector)} />
        </Route>
        <Route {...pages[Page.JumioConnector]}>
          <JumioConnector onNext={() => onChangePage(Page.JumioProcessing)} />
        </Route>
        <Route {...pages[Page.JumioProcessing]}>
          <JumioProcessing onNext={() => onChangePage(Page.ProfilePersonalDetails)} onRetryJumio={() => onChangePage(Page.JumioConnector)} />
        </Route>
        <Route {...pages[Page.ProfilePersonalDetails]}>
          <ProfilePersonalDetails onNext={() => onChangePage(Page.ProfileYourAddress)} />
        </Route>
        <Route {...pages[Page.ProfileYourAddress]}>
          <ProfileYourAddress onPrevious={() => onChangePage(Page.ProfilePersonalDetails)} onNext={() => onChangePage(Page.ProfileEmployment)} />
        </Route>
        <Route {...pages[Page.ProfileEmployment]}>
          <ProfileEmployment onPrevious={() => onChangePage(Page.ProfileYourAddress)} onNext={() => onChangePage(Page.FinancialBackground)} />
        </Route>
        <Route {...pages[Page.FinancialBackground]}>
          <FinancialBackground onPrevious={() => onChangePage(Page.ProfileEmployment)} onNext={() => onChangePage(Page.ProfileTaxDetails)} />
        </Route>
        <Route {...pages[Page.ProfileTaxDetails]}>
          <ProfileTaxDetails onPrevious={() => onChangePage(Page.FinancialBackground)} onNext={() => onChangePage(Page.KnowledgeExperience)} />
        </Route>
        <Route {...pages[Page.KnowledgeExperience]}>
          <KnowledgeExperience onPrevious={() => onChangePage(Page.ProfileTaxDetails)} onNext={() => onChangePage(Page.TermsConfirmation)} />
        </Route>
        <Route {...pages[Page.TermsConfirmation]}>
          <TermsConfirmation onPrevious={() => onChangePage(Page.KnowledgeExperience)} onNext={() => onChangePage(Page.AccountReadyInformation)} />
        </Route>
        <Route {...pages[Page.AccountReadyInformation]}>
          <AccountReadyInformation onStart={() => redirectToExternalUrl(SGP_CMC_PLATFORM_SITE)} onGoBack={() => onChangePage(Page.TermsConfirmation)} />
        </Route>
        <Route {...pages[Page.Home]}>
          <Redirect
            to={{
              pathname: pages[page].path,
              search: window.location.search
            }}
          />
        </Route>
      </Switch>
    </>
  );
};
