/* eslint-disable no-param-reassign */
import { configureStore, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  Brand,
  CashAccountDetailsResponse,
  EmploymentDetails,
  OnlineApplicationResponse,
  PersonalAddressDetails,
  PersonalDetails,
  SubmitApplicationResponseModel
} from '@cmctechnology/phoenix-stockbroking-api-client';
import hash from 'object-hash';
import { load, LoadOptions, RLSOptions, save } from 'redux-localstorage-simple';
import { AccountTypeInput, ApplicationStatus, PaymentMethod } from '../models/application';
import { defaultTaxDetails, IProfile, ITaxDetails } from '../models/profile';
import { defaultAddress, defaultDateInDayMonthYear } from '../models/common';
import { Page } from '../models/page';
import { ApiRequest, ApiRequestStatus, IApiRequestResult } from '../models/apiRequestResult';
import {
  IdentificationType,
  IdentificationValidationStatus,
  IDriversLicenceDetails,
  IIdentificationDetails,
  IMedicareDetails,
  IPassportDetails
} from '../models/identification';
import { IPEPQuestionsModel } from '../models/pepQuestions';
import { ReviewSection } from '../models/reviewAndSubmit';
import { IdVerifyProvider } from '../constants/sgp/myinfo';
import { LOCAL_STORAGE_DEBOUNCE_SAVE_IN_MS, LOCAL_STORAGE_NAMESPACE_PREFIX, REDUX_STATE_PERSIST_PART, STORE_NAME } from '../constants/store';
import {
  ISgpEmployment,
  ISgpFinancialBackground,
  ISgpKnowledgeExperience,
  ISgpLegalTerms,
  ISgpProfile,
  ISgpTaxInformation,
  ISgpAddressDetails,
  ISgpPersonalDetails
} from '../models/sgp/profile';
import { EMPTY_STRING } from '../constants/commonConstants';

export interface IApiResults extends Record<ApiRequest, IApiRequestResult> {}

type OptionalString = string | null | undefined;

export interface IStore {
  local: {
    accountType?: AccountTypeInput;
    paymentMethod?: PaymentMethod;
    authenticatedAt?: number;
    page: Page;
    termsAndConditions: string[];
    apiResults: IApiResults;
    reviewSection?: ReviewSection;
    applicationStatus: ApplicationStatus;
    applicationSubmitResponse?: OnlineApplicationResponse;
    applicationModalWaitingTimeElapsed: boolean;
    latestApplicationId?: string;
    cashAccountDetails?: CashAccountDetailsResponse;
    identityVerifyNeedLongerWaitingTime: boolean;
    identityVerifyTimeout: boolean;
    identityVerifyInProgressAfterLogin: boolean;
    identityVerifyMessageId?: string;
    promoCode?: string;
    brand?: Brand;
    sgp: {
      idVerifyProvider?: IdVerifyProvider;
      personDataFromMyInfoResponse?: ISgpProfile;
      jumioAuthoriseUrl?: OptionalString;
      applicationSubmitResponse?: SubmitApplicationResponseModel;
      promoCode?: string;
    };
    analyticsEnabled: boolean;
    trackingApplicationSubmitted: boolean;
  };
  // All personally identifying information (PII) should go in remote
  remote: {
    email: string;
    mobile: string;
    profile: IProfile;
    identificationDetails: IIdentificationDetails;
    sgp: {
      profile: ISgpProfile;
    };
    pepQuestions?: IPEPQuestionsModel;
  };
  // persist to localstorage with partial states which has to be the root-level for the state-tree required by the localstorage package
  // Currently the 'local' part is not suitable to fully stored to localstorage like page and other api call result
  [REDUX_STATE_PERSIST_PART]?: {
    sgp: {
      workflowId?: OptionalString;
      completeMyInfoProcess?: boolean;
      countryOfResidence?: string;
    };
  };
}

export type Identification = {
  profile: IProfile;
  identificationDetails: IIdentificationDetails;
  identityVerifyInProgressAfterLogin: boolean;
  identityVerifyMessageId?: string;
};

const defaultApiRequest: IApiRequestResult = {
  status: ApiRequestStatus.NotSubmitted,
  errorCode: EMPTY_STRING
};

export const createDefaultApiRequestResults = (): IApiResults => {
  const apiResults: IApiResults = {} as IApiResults;
  Object.keys(ApiRequest).forEach((key) => {
    apiResults[key as ApiRequest] = defaultApiRequest;
  });
  return apiResults;
};

export const defaultApiResults = createDefaultApiRequestResults();

export const defaultStore: IStore = {
  local: {
    page: Page.Home,
    termsAndConditions: [],
    apiResults: defaultApiResults,
    applicationStatus: ApplicationStatus.NotStarted,
    applicationModalWaitingTimeElapsed: false,
    identityVerifyNeedLongerWaitingTime: false,
    identityVerifyTimeout: false,
    identityVerifyInProgressAfterLogin: false,
    brand: Brand.cmc,
    sgp: {},
    analyticsEnabled: false,
    trackingApplicationSubmitted: false
  },
  remote: {
    email: EMPTY_STRING,
    mobile: EMPTY_STRING,
    profile: {
      personalDetails: { title: EMPTY_STRING, firstName: EMPTY_STRING, middleName: EMPTY_STRING, lastName: EMPTY_STRING, dob: defaultDateInDayMonthYear },
      addressDetails: {
        residentialAddress: defaultAddress,
        postalSameAsResidential: true,
        postalAddress: defaultAddress
      },
      taxDetails: defaultTaxDetails,
      employmentDetails: { occupation: EMPTY_STRING }
    },
    identificationDetails: { status: IdentificationValidationStatus.NotStarted },
    sgp: {
      profile: {
        personalDetails: {
          firstName: EMPTY_STRING,
          middleName: EMPTY_STRING,
          lastName: EMPTY_STRING,
          aliasName: EMPTY_STRING,
          hanyuPinyinName: EMPTY_STRING,
          hanyuPinyinAliasName: EMPTY_STRING,
          countryOfResidence: EMPTY_STRING,
          dateOfBirth: defaultDateInDayMonthYear,
          nationality: EMPTY_STRING,
          nric: EMPTY_STRING,
          fin: EMPTY_STRING,
          passExpiryDate: defaultDateInDayMonthYear,
          identityDocumentType: EMPTY_STRING,
          title: EMPTY_STRING,
          useClientEnteredName: false,
          clientEnteredFirstName: EMPTY_STRING,
          clientEnteredMiddleName: EMPTY_STRING,
          clientEnteredLastName: EMPTY_STRING,
          fullName: EMPTY_STRING
        },
        addressDetails: {
          line1: EMPTY_STRING,
          line2: EMPTY_STRING,
          line3: EMPTY_STRING,
          city: EMPTY_STRING,
          postCode: EMPTY_STRING,
          countryCode: EMPTY_STRING,
          isManualEntry: false
        },
        financialBackground: {
          annualIncome: EMPTY_STRING,
          valueOfSavingsInvestments: EMPTY_STRING
        },
        taxInformation: {
          primaryCountryCode: EMPTY_STRING,
          primaryTaxNumber: EMPTY_STRING,
          otherCountryCode: EMPTY_STRING,
          otherTaxNumber: EMPTY_STRING
        },
        knowledgeExperience: {
          investmentExperience: EMPTY_STRING,
          education: EMPTY_STRING,
          workExperience: EMPTY_STRING
        },
        termsAndConditions: []
      }
    }
  },
  [REDUX_STATE_PERSIST_PART]: {
    sgp: { completeMyInfoProcess: false }
  }
};

const createStoreSlice = (initialState: IStore) =>
  createSlice({
    name: STORE_NAME,
    initialState,
    reducers: {
      storeReset: () => defaultStore,
      brandUpdated: (store, action: PayloadAction<Brand | undefined>) => {
        store.local.brand = action.payload;
      },
      authenticatedAtUpdated: (store, action: PayloadAction<number | undefined>) => {
        store.local.authenticatedAt = action.payload;
      },
      accountTypeSelected: (store, action: PayloadAction<AccountTypeInput>) => {
        store.local.accountType = action.payload;
      },
      reviewSectionSelected: (store, action: PayloadAction<ReviewSection>) => {
        store.local.reviewSection = action.payload;
      },
      reviewSectionClear: (store) => {
        store.local.reviewSection = undefined;
      },
      paymentMethodSelected: (store, action: PayloadAction<PaymentMethod>) => {
        store.local.paymentMethod = action.payload;
      },
      personalDetailsSubmitted: (store, action: PayloadAction<PersonalDetails>) => {
        store.remote.profile.personalDetails = action.payload;
        store.remote.profile.personalDetails.validated = true;
      },
      validatedAddressDetailsSubmitted: (store, action: PayloadAction<PersonalAddressDetails>) => {
        store.remote.profile.addressDetails = action.payload;
        store.remote.profile.addressDetails.validated = true;
      },
      taxDetailsSubmitted: (store, action: PayloadAction<ITaxDetails>) => {
        store.remote.profile.taxDetails = action.payload;
        store.remote.profile.taxDetails.validated = true;
      },
      employmentDetailsSubmitted: (store, action: PayloadAction<EmploymentDetails>) => {
        store.remote.profile.employmentDetails = action.payload;
      },
      driversLicenceDetailsSubmitted: (store, action: PayloadAction<IDriversLicenceDetails>) => {
        store.remote.identificationDetails.driversLicence = action.payload;
      },
      medicareDetailsSubmitted: (store, action: PayloadAction<IMedicareDetails>) => {
        store.remote.identificationDetails.medicare = action.payload;
        store.remote.identificationDetails.medicare.validated = true;
      },
      passportDetailsSubmitted: (store, action: PayloadAction<IPassportDetails>) => {
        store.remote.identificationDetails.passport = action.payload;
      },
      identificationValidationStatusUpdated: (store, action: PayloadAction<IdentificationValidationStatus>) => {
        store.remote.identificationDetails.status = action.payload;
      },
      identificationValidationMessageIdUpdated: (store, action: PayloadAction<string>) => {
        store.local.identityVerifyMessageId = action.payload;
      },
      identityQuestionsRequiredUpdated: (store, action: PayloadAction<boolean | undefined>) => {
        store.remote.identificationDetails.identityQuestionsRequired = action.payload;
      },
      pepQuestionsAnswered: (store, action: PayloadAction<IPEPQuestionsModel>) => {
        store.remote.pepQuestions = action.payload;
      },
      applicationSubmitted: (store, action: PayloadAction<OnlineApplicationResponse>) => {
        store.local.applicationSubmitResponse = action.payload;
      },
      applicationStatusUpdated: (store, action: PayloadAction<ApplicationStatus>) => {
        store.local.applicationStatus = action.payload;
      },
      termsAndConditionsSubmitted: (store, action: PayloadAction<string[]>) => {
        store.local.termsAndConditions = action.payload;
      },
      pageChanged: (store, action: PayloadAction<Page>) => {
        store.local.page = action.payload;
      },
      apiRequestSent: (store, action: PayloadAction<ApiRequest>) => {
        const apiRequest = action.payload;
        store.local.apiResults[apiRequest] = {
          status: ApiRequestStatus.InProgress,
          errorCode: EMPTY_STRING
        };
      },
      apiRequestReset: (store, action: PayloadAction<ApiRequest>) => {
        const apiRequest = action.payload;
        store.local.apiResults[apiRequest] = {
          status: ApiRequestStatus.NotSubmitted,
          errorCode: EMPTY_STRING
        };
      },
      apiRequestSucceeded: (store, action: PayloadAction<ApiRequest>) => {
        const apiRequest = action.payload;
        store.local.apiResults[apiRequest] = {
          status: ApiRequestStatus.Success
        };
      },
      apiRequestFailed: (store, action: PayloadAction<{ apiRequest: ApiRequest; errorCode?: string | null }>) => {
        const { apiRequest, errorCode } = action.payload;
        store.local.apiResults[apiRequest] = {
          status: ApiRequestStatus.Failed,
          errorCode
        };
      },
      createLoginRestarted: (store) => {
        store.local.apiResults[ApiRequest.SignUp] = defaultApiRequest;
        store.local.apiResults[ApiRequest.VerifyYourEmail] = defaultApiRequest;
        store.local.apiResults[ApiRequest.ResendConfirmationCode] = defaultApiRequest;
        store.local.apiResults[ApiRequest.SubmitApplication] = defaultApiRequest;
        store.local.apiResults[ApiRequest.ApplicationStatus] = defaultApiRequest;
      },
      userInfoUpdated: (store, action: PayloadAction<{ email: string; mobile: string }>) => {
        store.remote.email = action.payload.email;
        store.remote.mobile = action.payload.mobile;
      },
      identificationSelected: (store, action: PayloadAction<IdentificationType | undefined>) => {
        store.remote.identificationDetails.identificationType = action.payload;
      },
      applicationWaitingTimeElapsed: (store, action: PayloadAction<boolean>) => {
        store.local.applicationModalWaitingTimeElapsed = action.payload;
      },
      lastApplicationIdFetched: (store, action: PayloadAction<string | undefined>) => {
        store.local.latestApplicationId = action.payload;
      },
      cashAccountDetailsFetched: (store, action: PayloadAction<CashAccountDetailsResponse>) => {
        store.local.cashAccountDetails = action.payload;
      },
      resetApplicationSubmitResponse: (store) => {
        store.local.applicationSubmitResponse = undefined;
      },
      identityVerifyNeedLongerWaitingTime: (store, action: PayloadAction<boolean>) => {
        store.local.identityVerifyNeedLongerWaitingTime = action.payload;
      },
      identityVerifyTimeout: (store, action: PayloadAction<boolean>) => {
        store.local.identityVerifyTimeout = action.payload;
      },
      latestIdentificationFetched: (store, action: PayloadAction<Identification>) => {
        store.remote.profile = action.payload.profile;
        store.remote.identificationDetails = action.payload.identificationDetails;
        store.local.identityVerifyInProgressAfterLogin = action.payload.identityVerifyInProgressAfterLogin;
        store.local.identityVerifyMessageId = action.payload.identityVerifyMessageId;
      },
      promoCodeSubmitted: (store, action: PayloadAction<string>) => {
        store.local.promoCode = action.payload;
      },
      // sgp actions
      idVerifyProviderSelected: (store, action: PayloadAction<IdVerifyProvider>) => {
        store.local.sgp.idVerifyProvider = action.payload;
      },
      workflowIdFetched: (store, action: PayloadAction<OptionalString>) => {
        store.persist!.sgp.workflowId = action.payload;
      },
      JumioAuthoriseUrlFetched: (store, action: PayloadAction<OptionalString>) => {
        store.local.sgp.jumioAuthoriseUrl = action.payload;
      },
      personDataFromMyInfoFetched: (store, action: PayloadAction<ISgpProfile>) => {
        store.remote.sgp.profile = action.payload;
      },
      financialBackgroundSubmitted: (store, action: PayloadAction<ISgpFinancialBackground>) => {
        store.remote.sgp.profile.financialBackground = action.payload;
      },
      knowledgeExperienceSubmitted: (store, action: PayloadAction<ISgpKnowledgeExperience>) => {
        store.remote.sgp.profile.knowledgeExperience = action.payload;
      },
      sgpEmploymentSubmitted: (store, action: PayloadAction<ISgpEmployment>) => {
        store.remote.sgp.profile.employment = action.payload;
      },
      addressDetailsSubmitted: (store, action: PayloadAction<ISgpAddressDetails>) => {
        store.remote.sgp.profile.addressDetails = Object.assign(store.remote.sgp.profile.addressDetails, action.payload);
      },
      completeMyInfoProcess: (store, action: PayloadAction<boolean | undefined>) => {
        store.persist!.sgp.completeMyInfoProcess = action.payload;
      },
      personDataFromJumioFetched: (store, action: PayloadAction<ISgpProfile>) => {
        store.remote.sgp.profile = action.payload;
      },
      sgpPersonalDetailsSubmitted: (store, action: PayloadAction<ISgpPersonalDetails>) => {
        store.remote.sgp.profile.personalDetails = Object.assign(store.remote.sgp.profile.personalDetails, action.payload);
      },
      taxInformationSubmitted: (store, action: PayloadAction<ISgpTaxInformation>) => {
        store.remote.sgp.profile.taxInformation = action.payload;
      },
      legalTermsSubmitted: (store, action: PayloadAction<ISgpLegalTerms[]>) => {
        store.remote.sgp.profile.termsAndConditions = action.payload;
      },
      sgpApplicationSubmitted: (store, action: PayloadAction<SubmitApplicationResponseModel>) => {
        store.local.sgp.applicationSubmitResponse = action.payload;
      },
      resetSgpApplicationSubmitResponse: (store) => {
        store.local.sgp.applicationSubmitResponse = undefined;
      },
      spgCountryOfResidenceSelected: (store, action: PayloadAction<string | undefined>) => {
        store.persist!.sgp.countryOfResidence = action.payload;
      },
      promoCodeSubmittedSgp: (store, action: PayloadAction<string>) => {
        store.local.sgp.promoCode = action.payload;
      },
      analyticsEnabled: (store, action: PayloadAction<boolean>) => {
        store.local.analyticsEnabled = action.payload;
      },
      trackingApplicationSubmitted: (store, action: PayloadAction<boolean>) => {
        store.local.trackingApplicationSubmitted = action.payload;
      }
    }
  });

const LOCAL_STORAGE_NAMESPACE = `${LOCAL_STORAGE_NAMESPACE_PREFIX}_${hash(defaultStore)}`;

const localStorageSaveConfig: RLSOptions = {
  namespace: LOCAL_STORAGE_NAMESPACE,
  states: [REDUX_STATE_PERSIST_PART],
  debounce: LOCAL_STORAGE_DEBOUNCE_SAVE_IN_MS
};
const localStorageLoadConfig: LoadOptions = {
  namespace: LOCAL_STORAGE_NAMESPACE,
  states: [REDUX_STATE_PERSIST_PART],
  preloadedState: defaultStore,
  disableWarnings: true
};
const loadedStore = load(localStorageLoadConfig) as IStore;

export const createStore = (initialStore: IStore = loadedStore) => {
  const slice = createStoreSlice(initialStore);
  const store = {
    ...configureStore<IStore>({
      reducer: slice.reducer,
      middleware: (getDefaultMiddleware) => [...getDefaultMiddleware(), save(localStorageSaveConfig)] as any
    }),
    actions: slice.actions
  };
  return store;
};

export const { actions, ...Store } = createStore();
