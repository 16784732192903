export const OPEN_EXTERNAL_URL_TARGET = '_blank';
export const OPEN_SELF_URL_TARGET = '_self';
export const CMC_PRIVACY_STATEMENT = 'https://www.cmcmarkets.com/en-au/privacy';
export const ONBOARDING_START_URL = 'https://oaf.cmcmarkets.com/en-au/onboarding-start?product=stb';
export const CMC_MARKETS_WEBSITE_URL = 'https://www.cmcmarkets.com';
export const CMC_MARKETS_WEBSITE_STOCKBROKING_URL = `${CMC_MARKETS_WEBSITE_URL}/en-au/stockbroking`;
export const CMC_MARKETS_WEBSITE_LEGAL = 'https://www.cmcmarkets.com/en-au/legal-documents';
export const CMC_MARKETS_WEBSITE_FAQ_URL = `${CMC_MARKETS_WEBSITE_URL}/en-au/support/faqs/stockbroking-product`;
export const BROKING_SERVICE_EMAIL = 'brokingservice@cmcmarkets.com';
export const CMC_PLATFORM_SITE = 'https://www.cmcmarketsstockbroking.com.au';
export const CMC_CONTACT_US = 'https://www.cmcmarkets.com/en-au/contact-us';
export const AU_DOMAIN = 'com.au';
export const CMC_MARKETS_INVEST_SIGNUP_API_URL = 'https://api-signup.cmcmarketsinvest.com';
export const AU_INVEST_CMC_MARKETS_SIGNUP_API_URL = 'https://api-signup.invest.cmcmarkets.com.au';
export const STAGING_SIGNUP_API_URL = 'https://api.staging.onboarding.sb.nonprod.aws.cmclabs.io';
export const CMC_APP_STORE_REDIRECT_URL = 'https://www.cmcmarketsstockbroking.com.au/RedirectToApp.aspx';
export const CMC_APPLE_APP_STORE_URL = 'https://apps.apple.com/au/app/id1559149606';
export const CMC_GOOGLE_PLAY_STORE_URL = 'https://play.google.com/store/apps/details?id=com.cmcmarketsstockbroking.android';
export const CMC_MARKETS_TC_LEGAL = 'https://www.cmcmarkets.com/en-au/legal-documents/stockbroking-legal-documents';
